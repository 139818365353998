<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>软件</h3>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <ul
        class="blocks-xs-2 blocks-md-2 blocks-lg-4 blocks-xxl-4 met-pager-ajax imagesize cover met-product-list met-grid"
        id="met-grid"
        data-scale="400x400"
        style="position: relative; height: 764.312px"
      >
        <li class="shown" style="position: absolute; left: 0px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=3"
                title="溯源系统https://chinasoft315.com"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1606015123.png"
                  alt="溯源系统https://chinasoft315.com"
                  data-lazyload="true"
                  src="/images/s1.png"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=3"
                title="溯源系统https://chinasoft315.com"
                class="block"
                target="_self"
                >溯源系统</a >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 334px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=2"
                title="资产管理系统"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1515654160.jpg"
                  alt="资产管理系统"
                  data-lazyload="true"
                  src="/images/s2.jpg"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=2"
                title="资产管理系统"
                class="block"
                target="_self"
                >资产管理系统</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 668px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=11"
                title="应用安全防护系统"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1545635222.jpg"
                  alt="应用安全防护系统"
                  data-lazyload="true"
                  src="/images/s3.jpg"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=11"
                title="应用安全防护系统"
                class="block"
                target="_self"
                >应用安全防护系统</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 1002px; top: 0px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=1"
                title="通用办公软件"
                target="_self"
              >
                <img
                  class="cover-image"
                  data-original="http://www.chinasofthuijin.cn/upload/thumb_src/400_400/1545399076.jpg"
                  alt="通用办公软件"
                  data-lazyload="true"
                  src="/images/s4.jpg"
                  style="display: inline"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=1"
                title="通用办公软件"
                class="block"
                target="_self"
                >通用办公软件</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 334px; top: 382px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=5"
                title="客户关系管理系统"
                target="_self"
              >
                <img
                  class="cover-image"
                  src="/images/s5.jpg"
                  alt="客户关系管理系统"
                  style="height: 308px"
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=5"
                title="客户关系管理系统"
                class="block"
                target="_self"
                >客户关系管理系统</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
        <li class="shown" style="position: absolute; left: 668px; top: 382px">
          <div class="card card-shadow">
            <figure class="card-header cover">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=4"
                title="集团财务管理与控制系统"
                target="_self"
              >
                <img
                  class="cover-image"
                  src="/images/s6.jpg"
                  alt="集团财务管理与控制系统"
                  style=""
                />
              </a>
            </figure>
            <h4 class="card-title m-0 p-x-10 font-size-16 text-xs-center">
              <a
                href="http://www.chinasofthuijin.cn/product/showproduct.php?id=4"
                title="集团财务管理与控制系统"
                class="block"
                target="_self"
                >集团财务管理与控制系统</a
              >
              <p class="m-b-0 m-t-5 red-600"></p>
            </h4>
          </div>
        </li>
      </ul>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
#met-grid{
    li{
        padding: 2%;
        margin: 1%;
        img{
            width: 300px;
            height: 300px;
        }
    }
}
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>